import axios from "axios";

console.log(process.env.REACT_APP_BASE_URL);

const customAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //baseURL: "http://localhost:3000",
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    "Access-Control-Allow-Origin": "*",
  },
  auth:{
    username : "admin",
    password : "secret"
  }
});

// Step-2: Create request, response & error handlers
const requestHandler = request => {
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call

  return request;
};

const errorHandler = error => {
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log(config)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
const responseHandler = response => {
  if (response.status === 401) {
    window.location = '/';
  }

  return response;
};

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;