import React, { useState, useEffect, useMemo, useRef } from "react";
import Pagination from "@material-ui/lab/Pagination";
import LeadService from "../../services/LeadService";
import { useTable, useFilters } from "react-table";

const LeadsHandRaiser = (props) => {
    const [leads, setLeads] = useState([]);
    const [searchTitle, setSearchTitle] = useState("");
    const leadsRef = useRef();

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const pageSizes = [10, 25, 50];

    leadsRef.current = leads;

    const onChangeSearchTitle = (e) => {
        const searchTitle = e.target.value;
        setSearchTitle(searchTitle);
    };

    const getRequestParams = (searchTitle, page, pageSize) => {
        let params = {};

        if (searchTitle) {
            params["title"] = searchTitle;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    };

    const retrieveLeads = () => {
        const params = getRequestParams(searchTitle, page, pageSize);

        LeadService.getAll(params)
            .then((response) => {
                const { leads, totalPages } = response.data;

                setLeads(leads);
                setCount(totalPages);

                console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(retrieveLeads, [page, pageSize]);


    const findByTitle = () => {
        setPage(1);
        retrieveLeads();
    };



    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value);
        setPage(1);
    };

    function DefaultColumnFilter({
                                     column: { filterValue, preFilteredRows, setFilter },
                                 }) {
        const count = preFilteredRows.length

        return (
            <input
                className="form-control"
                style={{ height: '100%' }}
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined)
                }}
                placeholder={``}
            />
        )
    }

    const defaultColumn = React.useMemo(
        () => ({
            width: 100,
            minWidth: 50,
            maxWidth: 100,
            // Default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'Leads submitted by test@ford.com email',
                columns: [
                    {
                        Header: 'Log Id',
                        accessor: 'nlogId'
                    },
                    {
                        Header: 'Brochure1',
                        accessor: 'szBrchr1'
                    },
                    {
                        Header: 'Brochure2',
                        accessor: 'szBrchr2'
                    },
                    {
                        Header: 'LastName',
                        accessor: 'szLastName'
                    },
                    {
                        Header: 'FirstName',
                        accessor: 'szFirstName'
                    },
                    {
                        Header: 'Address',
                        accessor: 'szAddress'
                    },
                    {
                        Header: 'City',
                        accessor: 'szCity'
                    },
                    {
                        Header: 'State',
                        accessor: 'szState'
                    },
                    {
                        Header: 'ZipCode',
                        accessor: 'szZipCode'
                    },
                    {
                        Header: 'EmailType',
                        accessor: 'szEmailType'
                    },
                    {
                        Header: 'ncrrntYear1',
                        accessor: 'ncrrntYear1'
                    },

                    {
                        Header: 'CrrntMake1',
                        accessor: 'szCrrntMake1'
                    },

                    {
                        Header: 'WhenNextCar',
                        accessor: 'szWhenNextCar'
                    },

                    {
                        Header: 'OrderDateTime',
                        accessor: 'dtOrderDateTime'
                    },

                    {
                        Header: 'szListID',
                        accessor: 'ListID'
                    },

                    {
                        Header: 'model_year',
                        accessor: 'model_year'
                    },

                    {
                        Header: 'QaCode',
                        accessor: 'szQaCode'
                    },

                    {
                        Header: 'Phone',
                        accessor: 'szPhone'
                    },

                    {
                        Header: 'Language',
                        accessor: 'szLanguage'
                    },
                    {
                        Header: 'BusinessName',
                        accessor: 'szBusinessName'
                    },
                ],
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data: leads,
        defaultColumn
    }, useFilters,);

    const scrollRef = useRef(null);

    const onWheel = e => {
        e.preventDefault();
        const container1 = scrollRef.current;
        const containerScrollPosition = scrollRef.current.scrollLeft;

        container1.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY
        });
    };

    return (
        <div className="list row">
                        <div className="centered">
                            <h3> NASAPI Lead Hand Raisers </h3>
                        </div>
            <div className="container2 col-md-12 list">
                <div className="mt-3">
                    {"Items per Page: "}
                    <select onChange={handlePageSizeChange} value={pageSize}>
                        {pageSizes.map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>

                    <Pagination
                        className="my-3"
                        count={count}
                        page={page}
                        siblingCount={1}
                        boundaryCount={1}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePageChange}
                    />
                </div>

                <div
                    ref={scrollRef}
                    className="container1"
                    id="container1"
                    onWheel={onWheel}
                >
                <table
                    className="table table-striped table-bordered"
                    {...getTableProps()}
                >
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    {/* Render the columns filter UI */}
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    );
};

export default LeadsHandRaiser;