import React from "react";
import ReactDOM from "react-dom";
import RGL, { WidthProvider } from "react-grid-layout";

const ReactGridLayout = WidthProvider(RGL);

const ManagerAccessList = () => {

    const gridItems = [
        { id: 1, name: "Leads Hand Raiser" , img: "handraiser.png", href: "/leadsHandRaiser"}
    ];

    var x = 0, y=0;

    return (
        <ReactGridLayout>
            {gridItems.map((item, i) => {
                x = x +2;
                if(x > 6){
                    x = 2;
                    y = y +2;
                }
                return (
                        <div key={item.id} className="grid-item" data-grid={{ x: x, y: 0, w: 3, h: 1, static: true }}>
                            <a className='navbar-brand' href={item.href}>
                                <img src={item.img} width="50" height="50" draggable="false" />
                                {item.name}
                            </a>
                        </div>
                );
            })}
        </ReactGridLayout>
    );
};
export default ManagerAccessList;