import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import { Header } from './shared/Header';
import ManagerAccessList from "./components/ManagerAccessList/ManagerList";
import LeadsHandRaiser from "./components/LeadsHandRaiser/LeadsHandRaiser";
import ContactUs from "./components/ContactUs/ContactUs";

function App() {
  return (
    <Router>
      <nav className="navbar navbar-expand">
        <div>
          <Header />
        </div>
      </nav>
      <div className=" mt-3">
        <Routes>
            <Route exact path="/" element={<ManagerAccessList/>}/>
            <Route exact path="/managerAccessList" element={<ManagerAccessList/>}/>
            <Route exact path="/leadsHandRaiser" element={<LeadsHandRaiser/>}/>
            <Route exact path="/ContactUs" element={<ContactUs/>}/>
        </Routes>
      </div>
    </Router>
  );
}
export default App;