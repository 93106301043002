import React, { useState, useEffect, useMemo, useRef } from "react";
import Pagination from "@material-ui/lab/Pagination";

const ContactUs = (props) => {

    return (
        <div className="list row">
            <div className="centered">
                <h3> NASAPI Contact Details </h3>
            </div>
            <div className="col-md-12 list">
                <div className="mt-3">
                    {"Primary Contact: Bob Gardiner (bgardine@ford.com)"}
                </div>
                <div className="mt-3">
                    {"Secondary Contact: Ranga Paturi(RPATURI@ford.com)"}
                </div>
                <div className="mt-3">
                    {"Git Link: https://pages.github.ford.com/nasapi/wiki-pages/index.html"}
                </div>
                <div className="mt-3">
                    {"Email Id: prxnasci@ford.com"}
                </div>
            </div>
        </div>
    );
};
export default ContactUs;