// Header.js

import React from 'react';
import ContactUs from "../components/ContactUs/ContactUs";

export function Header() {
    return (
        <nav className='navbar navbar-expand-lg'>
            <div className='container'>
                <div className="logo">
                    <div className="alignleft">

                    </div>
                    <a className='navbar-brand' href='/'>NASAPI Manager</a>
                </div>
                <div className="alignright">
                    <div className='collapse navbar-collapse' id='navbarNavAltMarkup'>
                        <div className='navbar-nav ml-auto'>
                            <a className='nav-item nav-link' href='/ContactUs'>Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}